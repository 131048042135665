import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/marekbaranowski/Desktop/dev/rxp-all/reactivepad/packages/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from "docz";
import { VideoWrapperFirebase } from "./VideoWrapper";
import Templates from "./Templates";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "introduction"
    }}>{`Introduction`}</h1>
    <p>{`Reactivepad brings spreadsheet capabilities into text editors, allowing to create sophisticated and dynamic content based on formulas, conditions, derivations, etc.`}</p>
    <p>{`Calculate as you type! Use Excel-like formulas in-between words; put tables with computed values right inside
your content; visualize data with beautiful charts.`}</p>
    <h3 {...{
      "id": "reactivepad-saves-time-increases-productivity-cuts-down-costs-by-reducing-tedious-time-consuming-copy-pasting-as-well-as-ensuring-data-correctness"
    }}>{`Reactivepad saves time, increases productivity, cuts down costs by reducing tedious time-consuming copy-pasting as well as ensuring data correctness.`}</h3>
    <hr></hr>
    <h2 {...{
      "id": "samples"
    }}>{`Samples`}</h2>
    <Playground __position={0} __code={'<Templates />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      VideoWrapperFirebase,
      Templates
    }} mdxType="Playground">
    <Templates mdxType="Templates" />
    </Playground>
    <hr></hr>
    <h2 {...{
      "id": "features"
    }}>{`Features`}</h2>
    <h3 {...{
      "id": "formulas"
    }}>{`Formulas`}</h3>
    <h4 {...{
      "id": "value"
    }}>{`Value`}</h4>
    <p>{`When creating a formula, you must fill in fields such as name, format, value. Then you can save it and refer to it whenever you need it.`}</p>
    <VideoWrapperFirebase src="rpx-formulas-1.1-value.mp4" mdxType="VideoWrapperFirebase" />
    <h4 {...{
      "id": "formula"
    }}>{`Formula`}</h4>
    <p>{`Think of formula as a cell in a spreadsheet, just in a text editor, surrounded by text. There are no coordinates to locate formula, so use meaningful names for references.`}</p>
    <VideoWrapperFirebase src="rpx-formulas-1.2-formulas.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "changing-the-name-of-the-formula"
    }}>{`Changing the name of the formula`}</h3>
    <p>{`In Reactivepad, everything updated automatically. When you find that you want to name the formula differently, you don't have to worry about the places where you referred to it. The name will change immediately and the data will be correct all the time.`}</p>
    <VideoWrapperFirebase src="rpx-formulas-2-change-name.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "formatting"
    }}>{`Formatting`}</h3>
    <p>{`When you create a new formula, you can choose its format depending on what value you need. Available formats are plain numeric value, decimal and percent.`}</p>
    <VideoWrapperFirebase src="rpx-formulas-3-formatting.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "using-excel-formulas"
    }}>{`Using Excel formulas`}</h3>
    <p>{`You probably know many formulas from Excel or another spreadsheets tool. You can use them all in Reactivepad, in the drop-down menu when entering values.`}</p>
    <VideoWrapperFirebase src="rpx-formulas-4-excel-formulas.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "references-to-other-values"
    }}>{`References to other values`}</h3>
    <p>{`When you have some formula created, you can easily refer to it by creating or editing another formula. You will find it in the formula editing a drop-down list. You can use it then for further calculations.`}</p>
    <VideoWrapperFirebase src="rpx-formulas-5-reference-other-values.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "referencing-to-table"
    }}>{`Referencing to table`}</h3>
    <p>{`Just like in the case of formulas names, you can use tables names for reference.`}</p>
    <h4 {...{
      "id": "column"
    }}>{`Column`}</h4>
    <p>{`If you want to refer to the entire column, wrap column name in square brackets and append it to the name of the table it belongs to: `}<inlineCode parentName="p">{`TableName[Column Name]`}</inlineCode>{`.`}</p>
    <VideoWrapperFirebase src="rpx-formulas-6.1-reference-table-column.mp4" mdxType="VideoWrapperFirebase" />
    <h2 {...{
      "id": "tables"
    }}>{`Tables`}</h2>
    <p>{`Not only formulas can take part in calculations but also tables. Tables are quite similar to spreadsheets but are more concise and readable. Data selection is not coordinates based (`}<inlineCode parentName="p">{`A1:G5`}</inlineCode>{`) but relies on names of tables and it's columns.`}</p>
    <VideoWrapperFirebase src="rpx-tables-1-create-table.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "adding-new-column"
    }}>{`Adding new column`}</h3>
    <p>{`If you want to add a new column, right-click on any cell of the table and a list will appear from which you can choose where this column should be located.`}</p>
    <VideoWrapperFirebase src="rpx-tables-2-add-column.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "column-formula"
    }}>{`Column formula`}</h3>
    <p>{`Columns can be computed with formulas. Select the column in which you want to create the formula and hover over its name. Then a drop-down menu will appear with the "formula" option. It can be both a formula format or a plain value.
Start with `}<inlineCode parentName="p">{`=`}</inlineCode>{` sign if you want to calculate, or just insert a plain value. `}<strong parentName="p">{`Note that cells in computed columns are not editable since they show the result of the calculation.`}</strong></p>
    <VideoWrapperFirebase src="rpx-tables-3-column-formula.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "formatting-column"
    }}>{`Formatting column`}</h3>
    <p>{`If you want the entire column to have a specific format, you need to hover over its name and then a drop-down menu will appear with the format options.`}</p>
    <VideoWrapperFirebase src="rpx-tables-4-formatting-column.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "referencing-formula-in-a-table"
    }}>{`Referencing formula in a table`}</h3>
    <p>{`Computed columns can reference formulas outside the table.`}</p>
    <VideoWrapperFirebase src="rpx-tables-5-referencing-formula-in-table.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "rename-column"
    }}>{`Rename column`}</h3>
    <p>{`If you want to change the name of the column, simply double click on its name and the editing mode will appear. It will be saved automatically.`}</p>
    <VideoWrapperFirebase src="rpx-tables-6-rename-column.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "rename-table"
    }}>{`Rename table`}</h3>
    <p>{`The name of the table is located under the table. You can change this name by simply double-clicking on it.`}</p>
    <VideoWrapperFirebase src="rpx-tables-7-rename-table.mp4" mdxType="VideoWrapperFirebase" />
    <h3 {...{
      "id": "columns-aggregations-in-the-footer"
    }}>{`Columns aggregations in the footer`}</h3>
    <p>{`In many cases, a column in a table needs to be aggregated, i.e. the total price of an order. The perfect place for that is table footer. Right-click on a table to trigger context menu and click "Show footer". Then you can choose one of the aggregation functions from the drop-down.`}</p>
    <VideoWrapperFirebase src="rpx–tables-8-footer.mp4" mdxType="VideoWrapperFirebase" />
    <hr></hr>
    <h2 {...{
      "id": "technical-overview"
    }}>{`Technical overview`}</h2>
    <p>{`Reactivepad consists of the calculation engine which is responsible for:`}</p>
    <ul>
      <li parentName="ul">{`parsing formulas, which means tokenizing string like `}<inlineCode parentName="li">{`=SUM(table1[Column1]) + 100`}</inlineCode>{`, then resolving and evaluating dependencies`}</li>
      <li parentName="ul">{`performing the actual calculations (with the support for ~380 Excel functions)`}</li>
      <li parentName="ul">{`ensuring consistency across the formula dependency graph, i.e. change in "a" recalculates "b" given that "b" is derived from "a" (directly or not)`}</li>
    </ul>
    <p>{`On top of that, there is a view layer that consumes the outcomes of the calculation engine and presents them in the UI.
We use React components here as they naturally react to data changes and re-update the UI.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      